

































































import { Vue, Component } from "vue-property-decorator";
import incidentCategoriesStore from "@/store/modules/incidentCategories";

import { Input, Button } from "element-ui";
import { IncidentCategories } from "@/store/models/incident";
import { AdminRouter } from "@/utils/routePathContsant";
import pagination from "@/components/UIComponents/Pagination.vue";

@Component({
  components: {
    Input,
    Button,
    pagination
  }
})
export default class IncidentCategory extends Vue {
  categoryPath = AdminRouter.incidentCategoryList;
  CategoryName = "";
  CategoryId = 0;
  centerDialogVisible = false;
  qCategories: any[] = [];

  get AdminRouter() {
    return AdminRouter;
  }
  get categories() {
    console.log(incidentCategoriesStore.incidentCategoryList)
    return incidentCategoriesStore.incidentCategoryList;
  }

  get complains(){
    console.log(incidentCategoriesStore.complainIncidentCategoryList)
     return incidentCategoriesStore.complainIncidentCategoryList;;
  }

  created() {
    incidentCategoriesStore.loadAllIncidentCategory();
    incidentCategoriesStore.loadAllComplainIncidentCategory();
  }

  edit(id: any) {
    this.$router.push(AdminRouter.incidentCategoryCreate + "?id=" + id);
  }
  deleteIncident(id: any) {
    this.centerDialogVisible = false;
    
    incidentCategoriesStore.deleteIncidentCategory(id);
    this.$snotify.success(this.CategoryName + " Category Deleted Successfully");
  }
  setStatus(row: any) {
    this.centerDialogVisible = true;
    this.CategoryName = row.CategoryName;
    this.CategoryId = row.Id;
  }

  //   submit(e: any) {
  //     incidentCategoriesStore.saveIncidentCategory();
  //   }
}
